<!-- 通知公告 -->
<template>
  <div class="notice-component">
    <div class="top-box">
      <div class="title-box">
        <p class="title">通知公告</p>
        <p class="react"></p>
      </div>

      <p class="more-box" @click="handleMore">
        更多<van-icon color="#333333" name="play" />
      </p>
    </div>
    <div class="list">
      <van-list
        v-if="list.length > 0"
        v-model="loading"
        :finished="finished"
        @load="onLoad"
      >
        <van-cell
          v-for="(item, i) in list"
          class="item"
          :key="i"
          @click="handleApply(item)"
        >
          <div class="item-box">
            <van-tag v-if="item.isTop == 1" plain class="unget" type="primary"
              >置顶</van-tag
            >
            <p slot="title" class="title">
              【{{ item.typeName }}】 {{ item.naTitle }}
            </p>
          </div>
          <div class="time">
            {{ item.onlineTime }} &nbsp;<van-icon name="arrow" size="14" />
          </div> </van-cell
      ></van-list>
      <van-empty
        class="empty-css"
        v-else
        description="暂无数据"
        :image-size="30"
      />
    </div>
  </div>
</template>

<script>
import { getNoticeList, getAllDictListByNotice } from "@/api/home/index.js";
export default {
  data() {
    return {
      msgType: [],
      active: "",
      list: [],
      loading: true,
      finished: true,
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.onLoad();
  },

  methods: {
    handleMore() {
      this.$router.push("/afficheList");
    },

    handleApply(item) {
      this.$router.push({
        path: "/afficheDetail",
        query: {
          id: item.naCode,
        },
      });
    },
    onLoad() {
      const params = {
        dictCode: "",
        homePageType: 0,
        isOnline: 0,
        isOnlyNotice: 0,
        isOwnerPublish: 0,
        naTitle: "",
        naType: 2, //1-草稿 2-已发布 3-已下线
        pageNum: 1,
        pageSize: 5,
        publishBeginTime: "",
        publishEndTime: "",
        subStaffName: "",
        isOnlyNotice: 1,
      };
      getNoticeList(params).then((res) => {
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            this.list = data.records.splice(0,3);
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.notice-component {
  margin-top: 0.2rem;
  width: 100%;
  max-height: 5.81rem;
  background: #ffffff;
  border-radius: 0.16rem;
  overflow: hidden;
  .top-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 0.01rem solid #e5e5e5;
    margin: 0rem 0.2rem;
    .title-box {
      display: flex;
      flex-direction: column;
      height: 1rem;

      .title {
        padding-top: 0.2rem;
        font-size: 0.34rem;
        font-weight: 500;
        color: #333333;
        line-height: 0.48rem;
      }
      .react {
        width: 1.36rem;
        height: 0.06rem;
        background: linear-gradient(
          135deg,
          #0094ee 0%,
          rgba(255, 255, 255, 0) 100%
        );
        border-radius: 0.04rem;
      }
    }
    .more-box {
      line-height: 1rem;
      font-weight: 500;
      color: #333333;
    }
  }
  .list {
    margin: 0rem 0.2rem;
    .item {
      display: flex;
      flex-direction: row;
      width: 100%;
      cursor: pointer;
      .item-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        overflow: hidden;
        align-items: center;
        flex: 1;
        .title {
          font-weight: 400;
          color: #333333;
          font-size: 0.3rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          flex: 1;
        }
        .unget {
          margin-right: 0.1rem;
          width: 0.7rem;
          height: 0.34rem;
          line-height: 0.4rem;
          font-size: 0.2rem;
          background: rgba(0, 148, 238, 0.1);
          // padding: 0.01rem 0.12rem;
          text-align: center;
          padding: 0rem 0.04rem;
        }
        /deep/ .van-tag {
          font-size: 0.2rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .time {
        width: 3rem;
        text-align: right;
        font-size: 0.22rem;
        color: #999999;
      }
    }
    ::v-deep .van-cell__value {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .empty-css {
    padding: 0.2rem 0rem;
  }
}
</style>
