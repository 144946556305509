<!--  -->
<template>
  <div class="">
    <van-dialog
      v-model="dialogPayroll"
      :show-cancel-button="true"
      :before-close="beforeClose"
      title="输入密码"
    >
      <van-field
        v-model="password"
        placeholder="请输入密码"
        clearable
        autosize
        maxlength="20"
        type="password"
      />
    </van-dialog>
  </div>
</template>

<script>
import { salaryPwdValid } from "@/api/salary/index.js";
export default {
  data() {
    return {
      password: "",
    };
  },
  props: {
    salaryMonth: {
      type: String,
      default: "",
    },
    dialogPayroll: Boolean,
  },

  components: {},

  computed: {},

  mounted() {},

  methods: {
    beforeClose(action, done) {
      if (action == "confirm") {
        salaryPwdValid({ password: this.password })
          .then((res) => {
            if (res) {
              let { code } = res;
              if (code == 200) {
                // this.dialogPayroll = false;
                this.$emit("updatePayroll", false);
                if (!this.salaryMonth) {
                  this.$router.push("/salaryList");
                } else {
                  this.$router.push({
                    path: "/salaryDetail",
                    query: {
                      id: this.salaryMonth,
                    },
                  });
                }

                done();
              }
            }
          })
          .catch((e) => {
            this.$emit("updatePayroll", false);
            done();
          });
      } else if (action == "cancel") {
        this.$emit("updatePayroll", false);
        done();
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
