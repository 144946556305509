<!--
 * @Description: 
 * @Version: 2.0
 * @Date: 2023-07-05 17:26:02
 * @LastEditors: Fiona.xu
 * @LastEditTime: 2023-07-13 11:18:07
-->
<!-- tabBar -->
<template>
  <div class="footer-wrapper">
    <van-tabbar
      v-model="activeBar"
      @change="changeActive"
      active-color="#0094ee"
    >
      <van-tabbar-item
        v-if="msgCount > 0"
        name="msgList"
        :badge="msgCount"
        @click="jump"
        icon="chat-o"
        >消息</van-tabbar-item
      >
      <van-tabbar-item v-else name="msgList" @click="jump" icon="chat-o"
        >消息</van-tabbar-item
      >

      <van-tabbar-item name="home" icon="cashier-o" @click="jump">
        工作台
      </van-tabbar-item>
      <van-tabbar-item name="user" icon="user-o" @click="jump"
        >我的</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>

<script>
import { countUnReadMsg } from "@/api/message/index.js";

export default {
  data() {
    return {
      msgCount: 0,
    };
  },

  props: {
    active: String,
    updateData: Boolean,
  },
  watch: {
    updateData: {
      handler(val) {
        this.getMsgCount();
      },
      immediate: true,
    },
  },

  components: {},

  computed: {
    activeBar: {
      get() {
        return this.active;
      },
      set() {},
    },
  },

  mounted() {},

  methods: {
    getMsgCount() {
      countUnReadMsg().then((res) => {
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            this.msgCount = data;
          }
        }
      });
    },
    changeActive(name) {
      this.active = name;
      this.$emit("updateActive", name);
    },
    jump() {
      this.$router.push(`/${this.active}`);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .van-tabbar-item {
  .van-info {
    white-space: nowrap;
  }
}
</style>
