<!-- 全部 -->
<template>
  <div class="all-component">
    <div class="top-box">
      <div class="title-box">
        <p class="title">全部</p>
        <p class="rect"></p>
      </div>
      <p class="more-box" @click="changeHieght">
        更多<van-icon color="#333333" name="play" />
      </p>
    </div>
    <div :class="['list-box', showArrow ? 'showArrow' : '']">
      <div class="item-box" v-if="allList.length">
        <div
          class="item"
          @click="handleItem(item)"
          v-for="(item, i) in allList"
          :key="i"
        >
          <p class="icon">
            <van-image
              width=".86rem"
              height=".86rem"
              fit="cover"
              :src="item.icon"
            />
          </p>
          <span class="itemName">{{ item.name }}</span>
        </div>
      </div>

      <van-empty v-else description="暂无数据" :image-size="60" />
      <payrollPass
        v-if="dialogPayroll"
        :dialogPayroll.sync="dialogPayroll"
        @updatePayroll="updatePayroll"
      />
    </div>
  </div>
</template>

<script>
import payrollPass from "@/components/payrollPass/index.vue";
import Icons from "@/assets/iconapprove/iconfont.json";

import { Toast } from "vant";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      dialogPayroll: false,
      allList: [
        {
          url: "/clockIn",
          count: null,
          name: "打卡",
          icon: require("@/assets/images/home/goClockIn3.png"),
        },
        {
          url: "/OAapproval",
          count: null,
          icon: "examine",
          name: "OA审批",
          icon: require("@/assets/images/home/0.png"),
        },
        {
          url: "/afficheList",
          count: null,
          icon: "huiyishiyuyue",
          name: "通知公告",
          icon: require("@/assets/images/home/1.png"),
        },
        {
          url: "/rulesSystem",
          count: null,
          icon: "huiyishiyuyue",
          name: "规章制度",
          icon: require("@/assets/images/home/2.png"),
        },

        {
          url: "/oneLevelorg",
          count: null,
          icon: "drxx91",
          name: "通讯录",
          icon: require("@/assets/images/home/3.png"),
        },

        {
          url: "/carMgr",
          count: null,
          name: "用车",
          icon: require("@/assets/images/home/carMgr.png"),
        },
        // {
        //   url: "/washCar",
        //   count: null,
        //   name: "洗车",
        //   icon: require("@/assets/images/home/washCard.png"),
        // },
        {
          url: "/salaryList",
          count: null,
          icon: "drxx91",
          name: "工资单",
          icon: require("@/assets/images/home/4.png"),
        },
        {
          url: "/publishNotice",
          count: null,
          icon: "fabu",
          name: "发布通知",
          icon: require("@/assets/images/home/5.png"),
        },
        {
          url: "/myPublish",
          count: null,
          icon: "fabu",
          name: "我的发布",
          icon: require("@/assets/images/home/6.png"),
        },
        {
          url: "/smartMeetingRoom",
          count: null,
          icon: "huiyishiyuyue",
          name: "智能会议室",
          icon: require("@/assets/images/home/7.png"),
        },
        {
          url: "/spaceListNew",
          count: null,
          icon: "shouwenguanli",
          name: "知识库",
          icon: require("@/assets/images/home/9.png"),
        },
        {
          url: "/assetsList",
          count: null,
          icon: "zichan-copy-copy",
          name: "固定资产",
          icon: require("@/assets/images/home/8.png"),
        },

        {
          url: "/suppliesHome",
          count: null,
          icon: "bangongyongpincaigou",
          name: "办公用品",
          icon: require("@/assets/images/home/10.png"),
        },
      ],
      showArrow: false,
    };
  },

  components: { payrollPass },

  computed: {
    iconArr() {
      return Icons.glyphs
        ? Icons.glyphs.map((item) => {
            return item.font_class;
          })
        : [];
    },
  },

  mounted() {
    const permission = localStorage.getItem("permission_H5");
    if (permission && permission.length > 0) {
      if (permission.indexOf("fixedAssets:Manager") > -1) {
        this.allList.push({
          url: "/assetsManList",
          count: null,
          icon: "zichan-copy-copy",
          name: "固定资产管理",
          icon: require("@/assets/images/home/8.png"),
        });
      }
    }
  },

  methods: {
    updatePayroll(res) {
      this.dialogPayroll = res;
    },
    handleItem(item) {
      if (!item.url) {
        Toast("该功能暂未开通");
      } else {
        this.$router.push(item.url);
      }
    },
    handleMore() {
      this.$router.push(`/OAapproval`);
    },
    iconShowName(name) {
      return this.iconArr.indexOf(name) > -1;
    },
    changeHieght() {
      this.showArrow = !this.showArrow;
    },
  },
};
</script>
<style lang="scss" scoped>
.all-component {
  margin-top: 0.2rem;
  width: 100%;

  background: #ffffff;
  border-radius: 0.16rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .top-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 0.01rem solid #e5e5e5;
    margin: 0rem 0.2rem;
    .title-box {
      display: flex;
      flex-direction: column;
      height: 1rem;

      .title {
        padding-top: 0.2rem;
        font-size: 0.34rem;
        font-weight: 500;
        color: #333333;
        line-height: 0.48rem;
      }
      .rect {
        width: 0.68rem;
        height: 0.06rem;
        background: linear-gradient(
          135deg,
          #0094ee 0%,
          rgba(255, 255, 255, 0) 100%
        );
        border-radius: 0.04rem;
      }
    }
    .more-box {
      line-height: 1rem;
      font-weight: 500;
      color: #333333;
    }
  }
  .list-box {
    width: 100%;
    height: 4.5rem;
    overflow: hidden;
    .item-box {
      display: flex;
      flex-direction: row;
      height: 1.6rem;
      flex-wrap: wrap;

      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 25%;
        margin-top: 0.2rem;
        .icon {
          text-align: center;
          background: #ffffff;

          width: 0.86rem;
          height: 0.86rem;
          border-radius: 50%;
          line-height: 0.86rem;
          margin-bottom: 0.12rem;
          .appicon {
            font-size: 0.4rem;
          }
        }
        .itemName {
          font-size: 0.24rem;
          width: 86%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          text-align: center;
        }
      }
      .item:nth-child(1),
      .item:nth-child(2),
      .item:nth-child(3),
      .item:nth-child(4),
      .item:nth-child(5) {
        margin-top: 0.2rem;
      }
    }
  }
  .row-arrow {
    width: 100%;
    text-align: center;
    padding: 0.1rem;
    margin-top: 0.1rem;
    .icon {
      font-size: 0.3rem;
    }
  }
  .showArrow {
    height: 6.4rem;
  }
  .show {
    height: 4.5rem;
  }
}
</style>
