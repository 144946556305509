<!-- 首页 -->
<template>
  <div class="home-page" v-if="!load">
    <!-- <TopWrapper class="home-header" :topObj="topObj" /> -->
    <div class="main-wrapper">
      <!-- 通知公告 -->
      <Notice />
      <!-- 待办事项 -->
      <ToDoList />
      <!-- 我的 -->
      <Mine />
      <!-- 全部 -->
      <All />
      <!-- 规章制度 -->
      <rules />
    </div>
    <TabBar
      class="footer-home"
      :active.sync="activeName"
      @updateActive="updateActive"
    />
  </div>
</template>

<script>
import TopWrapper from "@/components/topWrapper/index.vue";
import TabBar from "@/components/tabBar/index.vue";
import ToDoList from "./components/ToDolist.vue";
import Mine from "./components/Mine.vue";
import All from "./components/All.vue";
import Notice from "./components/Notice.vue";
import rules from "./components/rules.vue";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      topObj: {
        bgColor: "#0094EE",
        title: "首页",
        txtColor: "#fff",
        isBack: true,
        isMsg: false,
      },
      activeName: "home",
      load: true,
      refresh: null,
    };
  },

  components: { TopWrapper, TabBar, ToDoList, Mine, All, Notice, rules },

  computed: {},

  mounted() {
    // let Authorization_OA_H5 = localStorage.getItem("Authorization_OA_H5");
    let Authorization_OA_H5 = localStorage.getItem("Authorization_OA_H5");
    if (!Authorization_OA_H5) {
      this.$router.replace("/login");
    } else {
      this.load = false;
      this.getResource();
    }
    // this.refresh = setTimeout(() => {
    //   this.$router.go(0);
    // }, 30000);
  },
  beforeDestroy() {
    clearTimeout(this.refresh);
  },
  methods: {
    ...mapActions("login", ["getResource"]),
    updateActive(name) {
      this.activeName = name;
    },
  },
};
</script>
<style lang="scss" scoped>
.home-page {
  width: 100%;
  min-height: 100%;
  height: auto;
  background: #fafafa;
  .home-header {
    height: 0.8rem;
  }
  .main-wrapper {
    padding: 0.2rem;
    min-height: calc(100vh - 1.8rem);
  }
  .footer-home {
    height: 0.8rem;
  }
}
</style>
