<!-- 全部 -->
<template>
  <div class="all-component">
    <div class="top-box">
      <div class="title-box">
        <p class="title">我的</p>
        <p class="rect"></p>
      </div>
      <p class="more-box" @click="handleMore">
        更多<van-icon color="#333333" name="play" />
      </p>
    </div>
    <div class="list-box">
      <div class="item-box">
        <div class="item" @click="goClockIn">
          <span class="icon-box">
            <img
              src="@/assets/images/home/goClockIn2.png"
              style="width: 0.5rem; height: 0.5rem"
            />
          </span>
          <span class="itemName">打卡</span>
        </div>
        <div class="item" @click="handleItem('')">
          <span class="icon-box">
            <svg
              class="icon svg-icon"
              aria-hidden="true"
              style="width: 0.5rem; height: 0.5rem"
            >
              <use xlink:href="#shenpi"></use>
            </svg>
          </span>

          <span class="itemName">OA审批</span>
        </div>
        <div class="item" @click="goEmail">
          <span class="icon-box">
            <img
              src="@/assets/images/home/mail.png"
              style="width: 0.5rem; height: 0.5rem"
            />
          </span>

          <span class="itemName">邮箱</span>
        </div>

        <div
          class="item"
          @click="handleItem(item)"
          v-for="(item, i) in myList"
          :key="i"
        >
          <span class="icon-box"
            ><svg
              class="icon svg-icon"
              aria-hidden="true"
              style="width: 0.5rem; height: 0.5rem"
            >
              <use
                :xlink:href="'#' + (item.icon ? item.icon : 'shenpi')"
              ></use></svg
          ></span>

          <span class="itemName">{{ item.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icons from "@/assets/iconapprove/iconfont.json";
import { getProcessInfoList } from "@/api/home/index.js";
import { Toast } from "vant";

export default {
  data() {
    return {
      myList: [],
    };
  },

  components: {},

  computed: {
    iconArr() {
      return Icons.glyphs
        ? Icons.glyphs.map((item) => {
            return item.font_class;
          })
        : [];
    },
  },

  mounted() {
    this.getMyProcessList();
  },

  methods: {
    goEmail() {
      this.$router.push("/EmailHome");
    },
    handleItem(item) {
      let path = "/approvalCreat";
      if (item) {
        if (item.tagKey && item.tagKey == "CAR_APPLY_APPROVE") {
          path = "/carMgr";
        }
      } else {
        path = "/OAapproval";
      }

      this.$router.push({
        path: path,
        query: {
          bizCode: item.processCode,
        },
      });
    },
    handleMore() {
      this.$router.push({
        path: "/approvalList",
        query: {
          searchType: 1,
        },
      });
    },
    iconShowName(name) {
      return this.iconArr.indexOf(name) > -1;
    },
    getMyProcessList() {
      getProcessInfoList().then((res) => {
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            if (data.length > 7) {
              this.myList = data.slice(0, 3);
            } else {
              this.myList = data;
            }
          }
        }
      });
    },
    goClockIn() {
      this.$router.push("/clockIn");
    },
    goCarManage() {
      this.$router.push("/carMgr");
    },
  },
};
</script>
<style lang="scss" scoped>
.all-component {
  margin-top: 0.2rem;
  width: 100%;
  max-height: 4.5rem;
  background: #ffffff;
  border-radius: 0.16rem;
  overflow: hidden;
  .top-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 0.01rem solid #e5e5e5;
    margin: 0rem 0.2rem;
    .title-box {
      display: flex;
      flex-direction: column;
      height: 1rem;

      .title {
        padding-top: 0.2rem;
        font-size: 0.34rem;
        font-weight: 500;
        color: #333333;
        line-height: 0.48rem;
      }
      .rect {
        width: 0.68rem;
        height: 0.06rem;
        background: linear-gradient(
          135deg,
          #0094ee 0%,
          rgba(255, 255, 255, 0) 100%
        );
        border-radius: 0.04rem;
      }
    }
    .more-box {
      line-height: 1rem;
      font-weight: 500;
      color: #333333;
    }
  }
  .list-box {
    width: 100%;
    padding: 0.2rem 0rem;
    .item-box {
      display: flex;
      flex-direction: row;

      flex-wrap: wrap;

      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 25%;
        margin-top: 0.2rem;
        .icon-box {
          text-align: center;
          background: #ffffff;
          border: 0.01rem solid #ededed;
          width: 0.86rem;
          height: 0.86rem;
          border-radius: 50%;
          line-height: 0.86rem;
          margin-bottom: 0.12rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .appicon {
            font-size: 0.4rem;
          }
        }
        .itemName {
          font-size: 0.24rem;
          width: 86%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          text-align: center;
        }
      }
      .item:nth-child(1),
      .item:nth-child(2),
      .item:nth-child(3),
      .item:nth-child(4),
      .item:nth-child(5) {
        margin-top: 0.2rem;
      }
    }
  }
}
</style>
