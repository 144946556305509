<!--  -->
<template>
  <div class="todolist-component">
    <div class="top-box">
      <div class="title-box">
        <p class="title">待办事项</p>
        <p class="rect"></p>
      </div>
      <p class="more-box" @click="handleMore">
        更多<van-icon color="#333333" name="play" />
      </p>
    </div>

    <div class="list">
      <van-list
        v-if="list.length > 0"
        v-model="loading"
        :finished="finished"
        @load="onLoad"
      >
        <van-cell
          v-for="(item, i) in list"
          class="item"
          :key="i"
          @click="handleApply(item)"
        >
          <div class="item-box">
            <p slot="title" class="title">
              {{ item.applyTitle }}
            </p>

            <van-tag
              class="unget"
              round
              plain
              v-if="item.urgent == '2'"
              type="danger"
              >加急</van-tag
            >
          </div>
          <div class="time">
            {{ item.applyTime }}&nbsp;<van-icon name="arrow" size="14" />
          </div>
        </van-cell>
      </van-list>
      <van-empty
        class="empty-css"
        v-else
        description="暂无数据"
        :image-size="30"
      />
    </div>
  </div>
</template>

<script>
import { taskPage } from "@/api/home/index.js";
export default {
  data() {
    return {
      list: [],
      loading: true,
      finished: true,
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.onLoad();
  },

  methods: {
    onLoad() {
      const params = {
        applyEndTime: "",
        applyStartTime: "",
        applyUser: "",
        pageNum: 1,
        pageSize: 3,
        processCode: "",
        searchType: "3", //查询类型 1-已发布；2-我收到的；3-待处理；4-已处理
        processCode: "",
        descs: "urgent,apply_time",
      };
      taskPage(params).then((res) => {
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            this.list = data.records;
          }
        }
      });
    },
    handleApply(item) {
      let path = "/approvalDetail";
      if (item.tagKey && item.tagKey == "PURCHASE_APPROVE") {
        path = `/approvalProcureDetail`;
      }
      if (item.tagKey && item.tagKey == "CAR_APPLY_APPROVE") {
        path = `/approvalCarDetail`;
      }
      this.$router.push({
        path: path,
        query: {
          bizCode: item.bizCode,
          processCode: item.processCode,
          searchType: 3,
          applyNo: item.applyNo,
          tagKey: item.tagKey || "",
        },
      });
    },
    handleMore() {
      this.$router.push({
        path: "/approvalList",
        query: {
          searchType: 3,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.todolist-component {
  width: 100%;
  max-height: 4.03rem;
  background: #ffffff;
  border-radius: 0.16rem;
  overflow: hidden;
  .top-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 0.01rem solid #e5e5e5;
    margin: 0rem 0.2rem;
    .title-box {
      display: flex;
      flex-direction: column;
      height: 1rem;

      .title {
        padding-top: 0.2rem;
        font-size: 0.34rem;
        font-weight: 500;
        color: #333333;
        line-height: 0.48rem;
      }
      .rect {
        width: 1.36rem;
        height: 0.06rem;
        background: linear-gradient(
          135deg,
          #0094ee 0%,
          rgba(255, 255, 255, 0) 100%
        );
        border-radius: 0.04rem;
      }
    }
    .more-box {
      line-height: 1rem;
      font-weight: 500;
      color: #333333;
    }
  }
  .list {
    margin: 0rem 0.2rem;
    .item {
      display: flex;
      flex-direction: row;
      width: 100%;
      cursor: pointer;
      .item-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        overflow: hidden;
        align-items: center;
        flex: 1;
        .title {
          font-weight: 400;
          color: #333333;
          font-size: 0.3rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          flex: 1;
        }
        .unget {
          margin: 0rem 0.1rem;
          width: 0.72rem;
          height: 0.34rem;
          // padding: 0.01rem 0.12rem;
          text-align: center;
          padding: 0rem 0.04rem;
        }
        /deep/ .van-tag {
          font-size: 0.2rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .time {
        width: 3rem;
        text-align: right;
        font-size: 0.22rem;
        color: #999999;
      }
    }
    ::v-deep .van-cell__value {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .empty-css {
    padding: 0.2rem 0rem;
  }
}
</style>
