import request from "@/utils/request";

//获取我的常用办事项
export function getProcessInfoList(params) {
  return request({
    url: "/my/workspace/getProcessInfoList",
    method: "get",
    params: params,
  });
}
// 任务列表
export function taskPage(params) {
  return request({
    url: `/process-apply-info/taskPage`,
    method: "get",
    params,
  });
}
//公告列表
export function getNoticeList(data) {
  return request({
    url: `/notice/announcement/getNoticeList`,
    method: "post",
    data,
  });
}
//公告详情
export function noticeInfo(params) {
  return request({
    url: `/notice/announcement/info/${params}`,
    method: "get",
    // params: params,
  });
}
//获取公告模块下所有的字典列表
export function getAllDictListByNotice(params) {
  return request({
    url: `/notice/announcement/getAllDictListByNotice`,
    method: "get",
    params: params,
  });
}
/* 获取规章制度 */

export function getListByType(params) {
  return request({
    url: `/notice/announcement/getListByType`,
    method: "get",
    params: params,
  });
}
